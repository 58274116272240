import React, { useEffect, useState, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';

const AppConfig = (props) => {
  const toast = useRef(null);
  const [configs, setConfigs] = useState(null);
  const [isLoadingConfigs, setIsLoadingConfigs] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    props.setPageParams({
      alias: 'appconfig',
      title: 'App 設置'
    });
    getAppConfigs();
  }, []);

  const getAppConfigs = async () => {
    setIsLoadingConfigs(true);
    const responseConfigs = await fetchApi('appconfig/list');
    setConfigs(responseConfigs.data.configs.map((config) => {
      return {
        field: config.field,
        field_name: config.field_name,
        value: config.value
      };
    }));
    setIsLoadingConfigs(false);
  };

  const updateConfigs = (field, value) => {
    setConfigs(configs.map((config) => {
      if (config.field === field) {
        return {
          ...config,
          value: value
        };
      } else {
        return config;
      }
    }))
  };

  const saveConfigs = async (field) => {
    setIsSaving(true);
    const config = configs.filter(config => config.field === field)[0];
    const responseSaveConfigs = await fetchApi('appconfig/save', {
      field: config.field,
      value: config.value
    });
    toast.current.show({ severity: 'success', summary: 'App 設置', detail: `已成功儲存 App 設置（${config.field_name}）` });
    setIsSaving(false);
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-start"
          start={<TableTopTitle title="App 設置" subTitle="這裡可以進行各項 App 設置。" />}
        />
      </div>
      {configs === null ?
        <>
          <Skeleton className="mb-1 w-full h-4rem"></Skeleton>
          <Skeleton className="mb-1 w-full h-4rem"></Skeleton>
          <Skeleton className="mb-1 w-full h-4rem"></Skeleton>
        </>
        :
        <>
          <Accordion multiple activeIndex={null}>
            <AccordionTab header="職業介紹所牌照號碼">
              <div className="flex flex-column">
                <div className="col col-12 lg:col-6">
                  <div className="flex flex-column gap-2">
                    <label>牌照號碼</label>
                    <InputText value={configs.filter(config => config.field === 'license')[0]?.value} onChange={(e) => {updateConfigs('license', e.target.value)}} disabled={isSaving} />
                    <small>牌照號碼顯示於手機 App 的首頁底部。</small>
                  </div>
                </div>
                <div className="col col-12 flex justify-content-start align-items-end">
                  <Button label="儲存" size="small" onClick={() => {saveConfigs('license')}} disabled={isLoadingConfigs || isSaving} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="WhatsApp 連結">
              <div className="flex flex-column">
                <div className="col col-12 lg:col-6">
                  <div className="flex flex-column gap-2">
                    <label>WhatsApp 連結</label>
                    <InputText value={configs.filter(config => config.field === 'whatsapp_link')[0]?.value} onChange={(e) => {updateConfigs('whatsapp_link', e.target.value)}} disabled={isSaving} placeholder="https://api.whatsapp.com/send?phone=85290000000&text=Hello" />
                    <small>這是使用者在按下「WhatsApp 聯絡我們」按鈕時所跳至的網頁。</small>
                  </div>
                </div>
                <div className="col col-12 flex justify-content-start align-items-end">
                  <Button label="儲存" size="small" onClick={() => {saveConfigs('whatsapp_link')}} disabled={isLoadingConfigs || isSaving} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="首頁 YouTube 影片">
              <div className="flex flex-column">
                <div className="col p-0 flex flex-row">
                  <div className="col col-12 lg:col-6">
                    <div className="flex flex-column gap-2">
                      <label>首頁 YouTube 影片</label>
                      <InputText value={configs.filter(config => config.field === 'home_youtube_link')[0]?.value} onChange={(e) => {updateConfigs('home_youtube_link', e.target.value)}} disabled={isSaving} placeholder="OMOyBhSwiI0" />
                      <small>請輸入 YouTube 影片代碼（例如 https://www.youtube.com/watch?v=OMOyBhSwiI0，請輸入影片代碼：OMOyBhSwiI0）</small>
                    </div>
                  </div>
                  <div className="col col-12 lg:col-6">
                    <iframe width="180" height="100" src={`https://www.youtube.com/embed/${configs.filter(config => config.field === 'home_youtube_link')[0]?.value}?&autoplay=0`} frameborder="0" allowfullscreen className="border-round"></iframe>
                  </div>
                </div>
                <div className="col col-12 flex justify-content-start align-items-end">
                  <Button label="儲存" size="small" onClick={() => {saveConfigs('home_youtube_link')}} disabled={isLoadingConfigs || isSaving} />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </>
      }
      <Toast ref={toast} position="top-center" />
    </>
  );
};

export default AppConfig;